import Flex from "./playbook/Flex"
import Founder from "./Founder";
import LeadershipSVG from "./svgs/leadership-svg.js"

const founders = [
  {
    description:
      "PJ Singh is the CEO of Stream Financial. PJ has over 18 years of experience in Consumer Financial Services.  Before Stream, PJ held leadership roles at LendingClub, Capital One and HSBC. PJ has an MBA from the University of Chicago.",
    name: "PJ Singh",
    title: "CEO",
    image: "images/pj-singh-stream-financial.png",
  },
  {
    description:
      "Timothy Wenhold is the COO of Power Home Remodeling. He is not only responsible for the operations of the business but also for all aspects of technology, including NITRO, Power’s unique, state-of-the-art intelligence platform.",
    title: "President / Founding Member",
    name: "Timothy Wenhold",
    image: "images/timothy-wenhold-stream-financial.png",
  },
  {
    description:
      "Corey Schiller is the co-CEO of Power Home Remodeling. He provides strategic direction by immersing himself in each department and identifying both opportunities and needs. He is continually raising the standard for the leadership, expectations, and processes.",
    title: "Founding Member",
    name: "Corey Schiller",
    image: "images/corey-schiller-stream-financial.png",
  },
  {
    description:
      "Asher Raphael is the co-CEO of Power Home Remodeling. He has a passion for leadership development and company culture, and is uniquely focused on creating the best place to work in the country.",
    title: "Founding Member",
    name: "Asher Raphael",
    image: "images/asher-raphael-stream-financial.png",
  },
  {
    description:
      "Adam Kaliner founded Power Home Remodeling in 1992. He has overseen Power’s successful growth from a local window and siding company, to a full service home remodeler currently servicing 22  states across the country.",
    title: "Founding Member",
    name: "Adam Kaliner",
    image: "images/adam-kaliner-stream-financial.png",
  },
];

const Leadership = () => {
  return (
    <section className="bg-teal">
      <Flex paddingX="sm" paddingY="xl" orientation="column" align="center">
        <Flex marginBottom="lg" marginTop="xl" justify="center" align="center">
          {LeadershipSVG()}
        </Flex>
        <Flex justify="center" marginBottom="lg" wrap>
          {founders.map((founder) => (
            <Founder
              description={founder.description}
              image={`/${founder.image}`}
              name={founder.name}
              title={founder.title}
              key={founder.name}
            />
          ))}
        </Flex>
      </Flex>
    </section>
  )
};

export default Leadership;
