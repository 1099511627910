import Footer from '../../components/Footer'
import Hero from '../../components/Hero'
import Opportunities from '../../components/Opportunities'
import Leadership from '../../components/Leadership'
import Mission from "../../components/Mission"

import '../../styles/globals.css'
import '../../styles/fonts.css'
import 'playbook-ui/dist/playbook.css'
import 'playbook-ui/dist/reset.css'
import '@fortawesome/fontawesome-free/js/fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronDoubleDown, faLeaf, faUsers, faPiggyBank, faChartLine } from '@fortawesome/pro-regular-svg-icons'

library.add(faChevronDoubleDown, faLeaf, faUsers, faPiggyBank, faChartLine)

export default function Home() {
  return (
    <div>
      <main>
        <Hero />
        <Opportunities />
        <Leadership />
        <Mission />
        <Footer />
      </main>
    </div>
  )
}
