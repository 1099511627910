import styles from '../styles/Header.module.css'
import Button from '../components/playbook/Button'
import Flex from '../components/playbook/Flex'

const Header = () => (
  <header>
    <Flex
      align="center"
      justify="between"
      marginBottom="xl"
      paddingBottom="xl"
    >
      <img src="/images/stream-financial-logo.png" width="120" />
      <a
        href={"https://bp-sf.nortridgehosting.com/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          className={styles.headerButton}
          variant="primary"
          text="Sign In"
        />
      </a>
    </Flex>
  </header>
);

export default Header;
